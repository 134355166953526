import React, { useState } from 'react';
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import Layout from '../components/Layout';
import { SEO } from '../components/seo';
// import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import { useForm } from '../hooks'

const rowPadding = {
    padding: "100px 0"
}

export default function ContactSales() {
   
    // const initialState = {
    //     country: '',
    //     region: ''
    //     }
    
    // const [state, setState] = useState(initialState);
    // const { country, region } = state;

    // const firstnameInput = useRef(null);
    const [response, setResponse] = useState('');
    // const [token, setToken] = useState();

    const validate = (values) => {
        let errors = {};
        let errorMsg = 'This field is required!'

        const emailRegexPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);
        const phoneRegexPattern = new RegExp(/^[0-9\b]+$/);

        if(values.firstName.trim() === '') {
            errors.firstName = errorMsg;
        }
        if(values.lastName.trim() === '') {
            errors.lastName = errorMsg;
        }
        if(values.email.trim() === '') {
            errors.email = errorMsg;
        } else if(!emailRegexPattern.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        }
        if(values.phone.trim() === '') {
            errors.phone = errorMsg;
        } else if(!phoneRegexPattern.test(values.phone)) {
            errors.phone = 'This is not a valid phone number!';
        }
        if(values.companyName.trim() === '') {
            errors.companyName = errorMsg;
        }
        if(values.country.trim() === '') {
            errors.country = errorMsg;
        }
        if(values.region.trim() === '') {
            errors.region = errorMsg;
        }
        if(values.message.trim() === '') {
            errors.message = errorMsg;
        }

        return errors;
    };


    const contactSales = () => {
        console.log(values);

        fetch('https://contact.googolweb.com/contact-sales/', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "firstName": values.firstName,
            "lastName": values.lastName,
            "email": values.email,
            "phone": values.phone,
            "company": values.companyName,
            "country": values.country,
            "region": values.region,
            "message": values.message,
            // "token": token
        })
        })
        .then(res => res.json())
        .then(res => {
            // setLoading(false);
            setResponse(res);
            console.log(res);
        });
    };
    
    const { values, errors, onChange, onSubmit }  = useForm(
        contactSales, 
        { firstName: '', lastName: '', email: '', phone: '', companyName: '', country: '', region: '', message: '', test: '' }, 
        validate 
    );


    return (
        <Layout>
            <section style={{marginTop: '74px'}}>
                <div className='w-100 contact-us-bg'>
                <div className='container'>
                    <div className='row align-items-center' style={rowPadding}>
                        <div className='col-md-6 text-white'>
                            <h1 className='display-4'>Contact Sales</h1>
                            <p className='fs-5'>Get in touch with us, we’re happy to help.</p>
                        </div>
                    </div>
                </div>
                </div>

            </section>

            <section className='w-100 bg-white'>
                <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-8 mx-auto text-dark'>
                    <GoogleReCaptchaProvider
                                reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57"
                                container={{ // optional to render inside custom element
                                    element: "inline-badge",
                                    parameters: {
                                    badge: 'inline', // optional, default undefined
                                    }
                                }}>
                        <form onSubmit={onSubmit} className='row gy-3 py-5' style={rowPadding}>
                            <h2 className='display-5 text-center w-100'>How Can We Help?</h2>
                            
                            <p className='col-12 col-md-6'>
                                <label htmlFor="firstName" className='form-label'>First Name:</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className={`form-control ${errors.firstName && 'is-invalid'}`}
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={onChange}
                                />
                                {errors.firstName && (
                                    <span className='invalid-feedback'>
                                        {errors.firstName}
                                    </span>
                                )}
                            </p>
                            <p className='col-12 col-md-6'>
                                <label htmlFor="lastName" className='form-label'>Last Name:</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className={`form-control ${errors.lastName && 'is-invalid'}`}
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={onChange}
                                />
                                {errors.lastName && (
                                    <span className='invalid-feedback'>
                                        {errors.lastName}
                                    </span>
                                )}
                            </p>
                            <p className='col-12 col-md-6'>
                                <label htmlFor="email" className='form-label'>Work Email:</label>
                                <input
                                    type="text"
                                    id="email"
                                    className={`form-control ${errors.email && 'is-invalid'}`} 
                                    name="email"
                                    value={values.email}
                                    onChange={onChange}
                                />
                                {errors.email && (
                                    <span className='invalid-feedback'>
                                        {errors.email}
                                    </span>
                                )}
                            </p>
                            <p className='col-12 col-md-6'>
                                <label htmlFor="phone" className='form-label'>Phone:</label>
                                <input
                                    type="text"
                                    id="phone"
                                    className={`form-control ${errors.phone && 'is-invalid'}`} 
                                    name="phone"
                                    value={values.phone}
                                    onChange={onChange}
                                />
                                {errors.phone && (
                                    <span className='invalid-feedback'>
                                        {errors.phone}
                                    </span>
                                )}
                            </p>

                            <p className='col-12 col-md-12'>
                                <label htmlFor="companyName" className='form-label'>Company Name:</label>
                                <input
                                    type="text"
                                    id="companyName"
                                    className={`form-control ${errors.companyName && 'is-invalid'}`}
                                    name="companyName"
                                    value={values.companyName}
                                    onChange={onChange}
                                />
                                {errors.companyName && (
                                    <span className='invalid-feedback'>
                                        {errors.companyName}
                                    </span>
                                )}
                            </p>
                            
                            <p className='col-12 col-md-6'>
                                <label htmlFor="country" className='form-label'>Country/Region</label>

                                {/* <CountryDropdown 
                                    className={`form-select ${errors.country && 'is-invalid'}`}
                                    name='country' 
                                    id='country'
                                    defaultOptionLabel='- Select -'
                                    value={country}
                                    // defaultValue={country ? country : ""} 
                                    // onChange={(val) => this.selectCountry(val)}
                                    onChange={(val) => setState({ country: val })}
                                    // onChange={onChange}  
                                /> */}
                                <input
                                    type="text"
                                    id="country"
                                    className={`form-control ${errors.country && 'is-invalid'}`}
                                    name="country"
                                    value={values.country}
                                    onChange={onChange}
                                />
                                {errors.country && (
                                    <span className='invalid-feedback'>
                                        {errors.country}
                                    </span>
                                )}
                            </p>
                            <p className='col-12 col-md-6'>
                                <label htmlFor="region" className='form-label'>State/Province</label>

                                {/* <RegionDropdown 
                                    className={`form-select ${errors.region && 'is-invalid'}`} 
                                    name='region' 
                                    id='region'
                                    defaultOptionLabel='- Select -'
                                    country={country}
                                    value={region}
                                    // onChange={(val) => this.selectRegion(val)} 
                                    onChange={(val) => setState({...state, region: val })} /> */}
                                <input
                                    type="text"
                                    id="region"
                                    className={`form-control ${errors.region && 'is-invalid'}`}
                                    name="region"
                                    value={values.region}
                                    onChange={onChange}
                                />
                                {errors.region && (
                                    <span className='invalid-feedback'>
                                        {errors.region}
                                    </span>
                                )}
                            </p>

                            <p className="col-12">
                                <label htmlFor="message" className='form-label'>Additional information related to your business needs:</label>
                                <textarea 
                                id="message" 
                                name='message' 
                                rows="5" 
                                className={`form-control ${errors.message && 'is-invalid'}`} 
                                onChange={onChange} 
                                value={values.message}>
                                </textarea>
                                {errors.message && (
                                    <span className='invalid-feedback'>
                                        {errors.message}
                                    </span>
                                )}
                            </p>

                            <div id="inline-badge"></div>

                            <div className='col-12'>
                                {/* <button type="submit" id="submit" className="btn btn-orange default-padding shadow text-capitalize mt-3" role="button" style={{ width: '150px' }} onClick={onSubmit} disabled={loading}>
                                    {/* <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
                                    {/* {loading ? 'Sending...' : 'Send'}</button> */}

                                <button type="submit" className="btn btn-orange default-padding shadow text-capitalize mt-3" style={{ width: '150px' }}>Send</button>
                            </div>
                            {response && (
                                <div className='success-message'>
                                    {response}
                                </div>
                            )}
                        </form>
                    </GoogleReCaptchaProvider>
                    </div>
                </div>
                </div>
            </section>
        </Layout>
    );
}

export const Head = () => (
    <SEO title="Contact Sales" />
)

// }

// export default function ContactSales() {

//     selectCountry (val) {
//         this.setState({ country: val });
//       }

//     // const [name, setName] = useState('');
//     // const [email, setEmail] = useState('');
//     // const [message, setMessage] = useState('');
//     // const [loading, setLoading] = useState(false);
//     // const [response, setResponse] = useState(null);

//     // useEffect(() => {

//     //     window.onRecaptchaLoadCallback = onRecaptchaLoadCallback
    
//     //     const loadScriptByURL = (id, url, callback) => {
//     //       const isScriptExist = document.getElementById(id);
    
//     //       if (!isScriptExist) {
//     //         var script = document.createElement("script");
//     //         script.type = "text/javascript";
//     //         script.src = url;
//     //         script.id = id;
//     //         script.onload = function () {
//     //           if (callback) callback();
//     //         };
//     //         document.body.appendChild(script);
//     //       }
     
//     //       if (isScriptExist && callback) callback();
//     //     }
     
//     //     // load the script by passing the URL
//     //     loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoadCallback`, function () {
//     //       console.log("Script loaded!");
//     //     });
    
//     // }, []);

//     // const onRecaptchaLoadCallback = function() {
//     //     window.grecaptcha.render('submit', {
//     //         'sitekey': SITE_KEY,
//     //         'badge': 'inline',
//     //         'size': 'invisible',
//     //         'callback' : onSubmit
//     //     });
//     // }
    
//     // const onSubmit = function(token) {
//     //     // console.log('success!' + token);
//     //     setLoading(true);
//     //     // submitData(token);
               
//     // };

//     return (
//         <Layout>
//             <section style={{marginTop: '74px'}}>
//                 <div className='w-100 contact-us-bg'>
//                 <div className='container'>
//                     <div className='row align-items-center' style={rowPadding}>
//                     <div className='col-md-6 text-white'>
//                         <h1 className='display-4'>Contact Sales</h1>
//                         <p className='fs-5'>Get in touch with us, we’re happy to help.</p>
//                     </div>
//                     <div className='col-md-6'>
                        
//                     </div>
//                     </div>
//                 </div>
//                 </div>

//             </section>

//             <section className='w-100 bg-white'>
//                 <div className='container'>
//                 <div className='row'>
//                     <div className='col-12 col-md-8 mx-auto text-dark'>
//                         <form action="dosomething.html" method="POST" className='row gy-3' id='demo-form' e style={rowPadding}>
//                             <h2 className='display-5 text-center w-100'>How Can We Help?</h2>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="email" className='form-label'>Work Email:</label>
//                                 <input type="email" id="email" name="email" className='form-control' />
//                             </p>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="name" className='form-label'>Company Name:</label>
//                                 <input type="text" id="name" name="name" className='form-control' />
//                             </p>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="name" className='form-label'>First Name:</label>
//                                 <input type="text" id="name" name="name" className='form-control' />
//                             </p>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="name" className='form-label'>Last Name:</label>
//                                 <input type="text" id="name" name="name" className='form-control' />
//                             </p>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="tel" className='form-label'>Phone:</label>
//                                 <input type="tel" id="tel" name="tel" className='form-control' />
//                             </p>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="tel" className='form-label'>Inquiry:</label>
//                                 <input type="tel" id="tel" name="tel" className='form-control' />
//                             </p>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="tel" className='form-label'>Country/Region</label>
//                                 <select className="form-select" id='content' name='content' aria-label="">
//                                     <option selected>- Select -</option>
//                                 </select>
//                             </p>
//                             <p className='col-12 col-md-6'>
//                                 <label htmlFor="tel" className='form-label'>State/Province</label>
//                                 <select className="form-select" id='content' name='content' aria-label="">
//                                     <option selected>- Select -</option>
//                                 </select>
//                             </p>
//                             <p>
//                                 <label htmlFor="message" className='form-label'>Additional information related to your business needs:</label>
//                                 <textarea id="message" rows="5" className='form-control'></textarea>
//                             </p>

//                             <div className='col-12'>
//                                 {/* <button type="submit" id="submit" className="btn btn-orange default-padding shadow text-capitalize mt-3" role="button" style={{ width: '150px' }} onClick={onSubmit} disabled={loading}>
//                                     {/* <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
//                                     {/* {loading ? 'Sending...' : 'Send'}</button> */}

//                                 <button type="submit" id="submit" className="btn btn-orange default-padding shadow text-capitalize mt-3" role="button" style={{ width: '150px' }}>Send</button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//                 </div>
//             </section>
//         </Layout>
//     );
// }